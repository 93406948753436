var render = function render(){var _vm=this,_c=_vm._self._c;return _c('a-form-model',{ref:"form",attrs:{"hide-required-mark":true,"label-col":{
    span: 4,
    style: {
      textAlign: 'left'
    }
  },"model":_vm.form,"rules":_vm.rules,"wrapper-col":{
  span: 20
}}},[_c('a-form-model-item',{staticStyle:{"margin-bottom":"24px"},attrs:{"label":"用户名","prop":"username"}},[_c('a-input',{attrs:{"max-length":128,"placeholder":"请输入用户名","size":"large"},on:{"pressEnter":_vm.handleLogin},model:{value:(_vm.form.username),callback:function ($$v) {_vm.$set(_vm.form, "username", $$v)},expression:"form.username"}})],1),_c('a-form-model-item',{staticStyle:{"margin-bottom":"24px"},attrs:{"label":"密码","prop":"password"}},[_c('a-input-password',{attrs:{"placeholder":"请输入密码","max-length":128,"size":"large"},on:{"pressEnter":_vm.handleLogin},model:{value:(_vm.form.password),callback:function ($$v) {_vm.$set(_vm.form, "password", $$v)},expression:"form.password"}})],1),_c('a-form-model-item',{staticStyle:{"margin-bottom":"32px"},attrs:{"label":"验证码","prop":"captcha"},on:{"pressEnter":_vm.handleLogin}},[_c('div',{staticStyle:{"display":"flex"}},[_c('a-input',{attrs:{"placeholder":"请输入验证码","size":"large"},on:{"pressEnter":_vm.handleLogin},model:{value:(_vm.form.captcha),callback:function ($$v) {_vm.$set(_vm.form, "captcha", $$v)},expression:"form.captcha"}}),_c('div',{staticStyle:{"width":"128px","height":"40px","margin-left":"16px","cursor":"pointer"},on:{"click":_vm.refreshCaptcha}},[(_vm.captcha.data)?_c('img',{staticStyle:{"border-radius":"8px","border":"1px solid #e5e5e5"},attrs:{"src":`data:image/png;base64,${_vm.captcha.data}`,"alt":"","width":"128","height":"40"}}):_vm._e()])],1)]),_c('a-form-model-item',{attrs:{"wrapper-col":{ span: 24 }}},[_c('a-button',{attrs:{"block":true,"loading":_vm.loading,"size":"large","type":"primary"},on:{"click":_vm.handleLogin}},[_vm._v(" 登录 ")]),(_vm.ssoDirectUrl !== undefined)?_c('div',{staticStyle:{"margin":"4px 0"}},[_c('a',{staticStyle:{"float":"right","font-size":"16px","font-weight":"600"},attrs:{"type":"link"},on:{"click":_vm.ssoRedirect}},[_vm._v(" 单点登录 "),_c('a-icon',{attrs:{"type":"caret-right","theme":"filled"}})],1)]):_vm._e()],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }