<template>
  <a-form-model ref="form" :hide-required-mark="true" :label-col="{
    span: 4,
    style: {
      textAlign: 'left'
    }
  }" :model="form" :rules="rules" :wrapper-col="{
  span: 20
}">
    <a-form-model-item label="用户名" prop="username" style="margin-bottom: 24px">
      <a-input v-model="form.username" :max-length="128" placeholder="请输入用户名" size="large"
        @pressEnter="handleLogin"></a-input>
    </a-form-model-item>
    <a-form-model-item label="密码" prop="password" style="margin-bottom: 24px">
      <a-input-password v-model="form.password" placeholder="请输入密码" :max-length="128" size="large"
        @pressEnter="handleLogin"></a-input-password>
    </a-form-model-item>
    <a-form-model-item label="验证码" prop="captcha" @pressEnter="handleLogin" style="margin-bottom: 32px">
      <div style="display: flex">
        <a-input v-model="form.captcha" placeholder="请输入验证码" size="large" @pressEnter="handleLogin"></a-input>
        <div @click="refreshCaptcha" style="width: 128px; height: 40px; margin-left: 16px; cursor: pointer">
          <img v-if="captcha.data" :src="`data:image/png;base64,${captcha.data}`" alt="" width="128" height="40"
            style="border-radius: 8px; border: 1px solid #e5e5e5" />
        </div>
      </div>
    </a-form-model-item>
    <a-form-model-item :wrapper-col="{ span: 24 }">
      <a-button :block="true" :loading="loading" size="large" type="primary" @click="handleLogin">
        登录
      </a-button>
      <div v-if="ssoDirectUrl !== undefined" style="margin: 4px 0">
        <a @click="ssoRedirect" type="link" style="float: right; font-size: 16px; font-weight: 600">
          单点登录
          <a-icon type="caret-right" theme="filled" />
        </a>
      </div>
    </a-form-model-item>
  </a-form-model>
</template>

<script>
import { mapActions, mapGetters } from 'vuex'
import { getCaptcha } from '@/api/user'
import config from '@/config'
import { encrypt } from '@/utils'

export default {
  name: 'Login',
  data () {
    return {
      form: {
        username: '',
        password: '',
        captcha: ''
      },
      rules: {
        username: [
          {
            message: '请输入用户名',
            required: true,
            trigger: 'blur'
          }
        ],
        password: [
          {
            message: '请输入密码',
            required: true,
            trigger: 'blur'
          }
        ],
        captcha: [
          {
            message: '请输入验证码',
            required: true,
            trigger: 'blur'
          }
        ]
      },
      loading: false,
      captcha: {}
    }
  },
  computed: {
    ...mapGetters(['publicKey']),
    ...mapGetters(['ssoDirectUrl'])
  },
  mounted () {
    this.refreshCaptcha()
  },
  methods: {
    ...mapActions(['Login']),
    refreshCaptcha () {
      getCaptcha().then(res => {
        this.captcha = res.data
      })
    },
    handleLogin () {
      this.$refs.form.validate(valid => {
        if (valid) {
          this.loading = true
          const data = {
            username: this.form.username,
            password: encrypt(this.publicKey, this.form.password),
            id: this.captcha.id,
            captcha: this.form.captcha
          }
          this.Login(data)
            .then(res => {
              this.$notification.success({
                message: res.message,
                description: `欢迎使用 ${config.title}`
              })
              if (
                res.data.password_expires_in &&
                res.data.password_expires_in <= 7
              ) {
                this.$nextTick().then(() => {
                  this.$notification.warn({
                    message: '密码到期提醒',
                    description: `您的密码将于 ${res.data.password_expires_in} 天后过期, 请及时修改密码`
                  })
                })
              }
              this.$router.push({ name: 'Index' })
              this.$refs.form.resetFields()
            })
            .catch(() => {
              this.refreshCaptcha()
            })
            .finally(() => {
              this.loading = false
            })
        }
      })
    },
    ssoRedirect () {
      window.open(this.ssoDirectUrl, '_self')
    }
  }
}
</script>
